import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';
// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const SignInSignUp = React.lazy(() => import('../pages/auth/SignInSignUp'));
const LockScreen = React.lazy(() => import('../pages/auth/LockScreen'));

// auth2
const Login2 = React.lazy(() => import('../pages/auth2/Login2'));
const Logout2 = React.lazy(() => import('../pages/auth2/Logout2'));
const Register2 = React.lazy(() => import('../pages/auth2/Register2'));
const Confirm2 = React.lazy(() => import('../pages/auth2/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('../pages/auth2/ForgetPassword2'));
const LockScreen2 = React.lazy(() => import('../pages/auth2/LockScreen2'));
const SignInSignUp2 = React.lazy(() => import('../pages/auth2/SignInSignUp2'));

// landing
const Landing = React.lazy(() => import('../pages/landing/'));

// dashboard
const Dashboard1 = React.lazy(() => import('../pages/dashboard/Dashboard1/'));
// - hospital pages
const Hopitals = React.lazy(() => import('../pages/apps/Hospital/Hospitals'));
const HospitalCreate = React.lazy(() => import('../pages/apps/Hospital/HospitalCreate'));
const HospitalEdit = React.lazy(() => import('../pages/apps/Hospital/HospitalEdit'));
const HopitalDetail = React.lazy(() => import('../pages/apps/Hospital/HopitalDetails'));
const SearchHospitalDoctor = React.lazy(() => import('../pages/apps/Hospital/inner/SearchHospitalDoctor'));
const AddHospitalDoctor = React.lazy(() => import('../pages/apps/Hospital/inner/AddHospitalDoctor'));
const EditHospitalDoctor = React.lazy(() => import('../pages/apps/Hospital/inner/EditHospitalDoctor'));
const CreateHospitalService = React.lazy(() => import('../pages/apps/Hospital/inner/CreateService'));
const EditHospitalService = React.lazy(() => import('../pages/apps/Hospital/inner/EditService'));
const HospitalType = React.lazy(() => import('../pages/apps/Hospital/HospitalType'));
const HospitalTypeCreate = React.lazy(() => import('../pages/apps/Hospital/HospitalTypeCreate'));
const HospitalTypeEdit = React.lazy(() => import('../pages/apps/Hospital/HospitalTypeEdit'));
const HospitalUsers = React.lazy(() => import('../pages/apps/Hospital/HospitalUsers'));
const HospitalUserEdit = React.lazy(() => import('../pages/apps/Hospital/HospitalUserEdit'));
const HospitalUserCreate = React.lazy(() => import('../pages/apps/Hospital/HospitalUserCreate'));
const HospitalDetailUserEdit = React.lazy(() => import('../pages/apps/Hospital/inner/HospitalUserEdit'));
const CreateHospitalProcedure = React.lazy(() => import('../pages/apps/Hospital/inner/ProcedureCreate'));
const EditHospitalProcedure = React.lazy(() => import('../pages/apps/Hospital/inner/ProcedureEdit'));
const Packages = React.lazy(() => import('../pages/apps/Hospital/Packages'));
const createPackage = React.lazy(() => import('../pages/apps/Hospital/createPackage'));
const editPackage = React.lazy(() => import('../pages/apps/Hospital/editPackage'));
const EditHospitalAssociatedPackage = React.lazy(() => import('../pages/apps/Hospital/inner/PackagesEdit'));
const CreateHospitalAssociatedPackage = React.lazy(() => import('../pages/apps/Hospital/inner/CreatePackage'));

// - doctor pages
const Doctors = React.lazy(() => import('../pages/apps/Doctor/Doctors'));
const DoctorEdit = React.lazy(() => import('../pages/apps/Doctor/DoctorEdit'));
const DoctorCreate = React.lazy(() => import('../pages/apps/Doctor/DoctorCreate'));
const DoctorDetail = React.lazy(() => import('../pages/apps/Doctor/DoctorDetails'));
const SearchDoctor = React.lazy(() => import('../pages/apps/Doctor/SearchDoctor'));
const Departments = React.lazy(() => import('../pages/apps/Doctor/Departments'));
const Qualification = React.lazy(() => import('../pages/apps/Doctor/Qualification'));
const QualificationCreate = React.lazy(() => import('../pages/apps/Doctor/QualificationCreate'));
const QualificationEdit = React.lazy(() => import('../pages/apps/Doctor/QualificationEdit'));
const Specialization = React.lazy(() => import('../pages/apps/Doctor/Specialization'));
const CreateDepartment = React.lazy(() => import('../pages/apps/Doctor/DepartmentCreate'));
const DepartmentEdit = React.lazy(() => import('../pages/apps/Doctor/DepartmentEdit'));
const SpecializationCreate = React.lazy(() => import('../pages/apps/Doctor/SpecializationCreate'));
const specializationEdit = React.lazy(() => import('../pages/apps/Doctor/SpecializationEdit'));
// - procedure
const Procedure = React.lazy(() => import('../pages/apps/Procedure/Procedure'));
const ProcedureEdit = React.lazy(() => import('../pages/apps/Procedure/ProcedureEdit'));
// - facilities
const Facilities = React.lazy(() => import('../pages/apps/Facilities/Facilities'));
const FacilitiesCreate = React.lazy(() => import('../pages/apps/Facilities/FacilitiesCreate'));
const FacilitiesView = React.lazy(() => import('../pages/apps/Facilities/FacilitiesView'));
const FacilitiesEdit = React.lazy(() => import('../pages/apps/Facilities/FacilitiesEdit'));


// - services
const Services = React.lazy(() => import('../pages/apps/Services/Services'));
const ServicesEdit = React.lazy(() => import('../pages/apps/Services/ServicesEdit'));
// - blood bank
const BloodBank = React.lazy(() => import('../pages/apps/BloodBank/BloodBank'));
const BloodBankEdit = React.lazy(() => import('../pages/apps/BloodBank/BloodBankEdit'));
const BloodBankDetails = React.lazy(() => import('../pages/apps/BloodBank/BloodBankDetails'));
const BloodBankCreate = React.lazy(() => import('../pages/apps/BloodBank/BlooodBankCreate'));
// - ambulance
const Ambulance = React.lazy(() => import('../pages/apps/Ambulance/Ambulance'));
const AmbulanceProvider = React.lazy(() => import('../pages/apps/Ambulance/AmbulanceServiceProvider'));
const AmbulanceEdit = React.lazy(() => import('../pages/apps/Ambulance/AmbulanceEdit'));
const AmbulanceDetails = React.lazy(() => import('../pages/apps/Ambulance/AmbulanceDetails'));
const AmbulanceCreate = React.lazy(() => import('../pages/apps/Ambulance/AmbulanceCreate'));

// - rooms
const Rooms = React.lazy(() => import('../pages/apps/Rooms/Rooms'));
const RoomEdit = React.lazy(() => import('../pages/apps/Rooms/RoomEdit'));
const RoomView = React.lazy(() => import('../pages/apps/Rooms/RoomView'));
const RoomCreate = React.lazy(() => import('../pages/apps/Rooms/RoomCreate'));
// - hospy users
const HospyUsers = React.lazy(() => import('../pages/apps/HospyUsers/HospyUsers'));
const HospyUserEdit = React.lazy(() => import('../pages/apps/HospyUsers/HospyUserEdit'));
const HospyUserCreate = React.lazy(() => import('../pages/apps/HospyUsers/HospyUserCreate'));

// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar/'));
const Projects = React.lazy(() => import('../pages/apps/Projects/'));
const ProjectDetail = React.lazy(() => import('../pages/apps/Projects/Detail/'));
const ProjectForm = React.lazy(() => import('../pages/apps/Projects/ProjectForm'));

// - ecommece pages
const EcommerceDashboard = React.lazy(() => import('../pages/apps/Ecommerce/Dashboard/'));
const EcommerceProducts = React.lazy(() => import('../pages/apps/Ecommerce/Products'));
const ProductDetails = React.lazy(() => import('../pages/apps/Ecommerce/ProductDetails'));
const ProductEdit = React.lazy(() => import('../pages/apps/Ecommerce/ProductEdit'));
const Customers = React.lazy(() => import('../pages/apps/Ecommerce/Customers'));
const Orders = React.lazy(() => import('../pages/apps/Ecommerce/Orders'));
const OrderDetails = React.lazy(() => import('../pages/apps/Ecommerce/OrderDetails'));
const Sellers = React.lazy(() => import('../pages/apps/Ecommerce/Sellers'));
const Cart = React.lazy(() => import('../pages/apps/Ecommerce/Cart'));
const Checkout = React.lazy(() => import('../pages/apps/Ecommerce/Checkout'));
// - crm pages
const CRMDashboard = React.lazy(() => import('../pages/apps/CRM/Dashboard/'));
const CRMContacts = React.lazy(() => import('../pages/apps/CRM/Contacts/'));
const Opportunities = React.lazy(() => import('../pages/apps/CRM/Opportunities/'));
const CRMLeads = React.lazy(() => import('../pages/apps/CRM/Leads/'));
const CRMCustomers = React.lazy(() => import('../pages/apps/CRM/Customers/'));
// - email
const Inbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));
const EmailCompose = React.lazy(() => import('../pages/apps/Email/Compose'));
// - social
const SocialFeed = React.lazy(() => import('../pages/apps/SocialFeed/'));
// - companies
const Companies = React.lazy(() => import('../pages/apps/Companies/'));
// - tasks
const TaskList = React.lazy(() => import('../pages/apps/Tasks/List/'));
const TaskDetails = React.lazy(() => import('../pages/apps/Tasks/Details'));
const Kanban = React.lazy(() => import('../pages/apps/Tasks/Board/'));
// -contacts
const ContactsList = React.lazy(() => import('../pages/apps/Contacts/List/'));
const ContactsProfile = React.lazy(() => import('../pages/apps/Contacts/Profile/'));
// -tickets
const TicketsList = React.lazy(() => import('../pages/apps/Tickets/List/'));
const TicketsDetails = React.lazy(() => import('../pages/apps/Tickets/Details/'));
// - file
const FileManager = React.lazy(() => import('../pages/apps/FileManager'));

// extra pages
const Starter = React.lazy(() => import('../pages/other/Starter'));
const Timeline = React.lazy(() => import('../pages/other/Timeline'));
const Sitemap = React.lazy(() => import('../pages/other/Sitemap/'));
const Error404 = React.lazy(() => import('../pages/error/Error404'));
const Error404Two = React.lazy(() => import('../pages/error/Error404Two'));
const Error404Alt = React.lazy(() => import('../pages/error/Error404Alt'));
const Error500 = React.lazy(() => import('../pages/error/Error500'));
const Error500Two = React.lazy(() => import('../pages/error/Error500Two'));
// - other
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const FAQ = React.lazy(() => import('../pages/other/FAQ'));
const SearchResults = React.lazy(() => import('../pages/other/SearchResults/'));
const Upcoming = React.lazy(() => import('../pages/other/Upcoming'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Gallery = React.lazy(() => import('../pages/other/Gallery/'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));

// uikit
const Buttons = React.lazy(() => import('../pages/uikit/Buttons'));
const Avatars = React.lazy(() => import('../pages/uikit/Avatars'));
const Cards = React.lazy(() => import('../pages/uikit/Cards'));
const Portlets = React.lazy(() => import('../pages/uikit/Portlets'));
const TabsAccordions = React.lazy(() => import('../pages/uikit/TabsAccordions'));
const Progress = React.lazy(() => import('../pages/uikit/Progress'));
const Modals = React.lazy(() => import('../pages/uikit/Modals'));
const Notifications = React.lazy(() => import('../pages/uikit/Notifications'));
const Offcanvases = React.lazy(() => import('../pages/uikit/Offcanvas'));
const Placeholders = React.lazy(() => import('../pages/uikit/Placeholders'));
const Spinners = React.lazy(() => import('../pages/uikit/Spinners'));
const Images = React.lazy(() => import('../pages/uikit/Images'));
const Carousels = React.lazy(() => import('../pages/uikit/Carousel'));
const ListGroups = React.lazy(() => import('../pages/uikit/ListGroups'));
const EmbedVideo = React.lazy(() => import('../pages/uikit/EmbedVideo'));
const Dropdowns = React.lazy(() => import('../pages/uikit/Dropdowns'));
const Ribbons = React.lazy(() => import('../pages/uikit/Ribbons'));
const TooltipsPopovers = React.lazy(() => import('../pages/uikit/TooltipsPopovers'));
const GeneralUI = React.lazy(() => import('../pages/uikit/GeneralUI'));
const Typography = React.lazy(() => import('../pages/uikit/Typography'));
const Grid = React.lazy(() => import('../pages/uikit/Grid'));
const NestableList = React.lazy(() => import('../pages/uikit/NestableList'));
const DragDrop = React.lazy(() => import('../pages/uikit/DragDrop'));
const RangeSliders = React.lazy(() => import('../pages/uikit/RangeSliders'));
const Animation = React.lazy(() => import('../pages/uikit/Animation'));
const TourPage = React.lazy(() => import('../pages/uikit/TourPage'));
const SweetAlerts = React.lazy(() => import('../pages/uikit/SweetAlerts'));
const LoadingButtons = React.lazy(() => import('../pages/uikit/LoadingButtons'));

// widgets
const Widgets = React.lazy(() => import('../pages/uikit/Widgets'));

// icons
const TwoToneIcons = React.lazy(() => import('../pages/icons/TwoToneIcons/'));
const FeatherIcons = React.lazy(() => import('../pages/icons/FeatherIcons/'));
const Dripicons = React.lazy(() => import('../pages/icons/Dripicons/'));
const MDIIcons = React.lazy(() => import('../pages/icons/MDIIcons/'));
const FontAwesomeIcons = React.lazy(() => import('../pages/icons/FontAwesomeIcons/'));
const ThemifyIcons = React.lazy(() => import('../pages/icons/ThemifyIcons/'));
const SimpleLineIcons = React.lazy(() => import('../pages/icons/SimpleLineIcons/'));
const WeatherIcons = React.lazy(() => import('../pages/icons/WeatherIcons/'));

// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editors = React.lazy(() => import('../pages/forms/Editors'));

// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));

// maps
const GoogleMaps = React.lazy(() => import('../pages/maps/GoogleMaps'));

export interface RoutesProps {
    path: RouteProps['path'];
    name?: string;
    component?: RouteProps['component'];
    route?: any;
    exact?: RouteProps['exact'];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
    path: '/',
    exact: true,
    component: () => <Root />,
    route: Route,
};

// dashboards
const dashboardRoutes: RoutesProps = {
    path: '/dashboard',
    name: 'Dashboards',
    route: PrivateRoute,
    component: Dashboard1,
    icon: 'airplay',
    header: 'Navigation',
};

const hospitalAppRoutes = {
    path: '/apps/hospital',
    name: 'Hospital',
    route: PrivateRoute,
    roles: ['ADMIN'],
    icon: 'plus-square',
    children: [
        {
            path: '/apps/hospital/list',
            name: 'Hospitals',
            component: Hopitals,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/create',
            name: 'Hospitals',
            component: HospitalCreate,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/edit',
            name: 'Hospitals',
            component: HospitalEdit,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/details',
            name: 'Hospitals',
            component: HopitalDetail,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/doctor/search',
            name: 'Hospitals',
            component: SearchHospitalDoctor,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/doctor/add',
            name: 'Hospitals',
            component: AddHospitalDoctor,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/doctor/edit',
            name: 'Hospitals',
            component: EditHospitalDoctor,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/service/create',
            name: 'Hospitals',
            component: CreateHospitalService,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/service/edit',
            name: 'Hospitals',
            component: EditHospitalService,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/user/list',
            name: 'Hospital Users',
            component: HospitalUsers,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/user/create',
            name: 'Create Hospital User',
            component: HospitalUserCreate,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/user/edit',
            name: 'Edit Hospital User',
            component: HospitalUserEdit,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/inner/user/create',
            name: 'Create Hospital Detail Users',
            component: HospitalDetailUserEdit,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/procedure/create',
            name: 'Hospital Procedures',
            component: CreateHospitalProcedure,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/procedure/edit',
            name: 'Hospital Procedures',
            component: EditHospitalProcedure,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/type/list',
            name: 'Hospital Type',
            component: HospitalType,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/type/create',
            name: 'Hospital Type',
            component: HospitalTypeCreate,
            route: PrivateRoute,
        }, {
            path: '/apps/hospital/type/edit',
            name: 'Hospital Type',
            component: HospitalTypeEdit,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/Package/create',
            name: 'Hospital Type',
            component: CreateHospitalAssociatedPackage,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/Package/edit',
            name: 'Hospital Type',
            component: EditHospitalAssociatedPackage,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/package/list',
            name: 'Packages',
            component: Packages,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/createpackage',
            name: 'Packages',
            component: createPackage,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospital/editpackage',
            name: 'Packages',
            component: editPackage,
            route: PrivateRoute,
        }
    ],
};

const doctorAppRoutes = {
    path: '/apps/doctor',
    name: 'Doctor',
    route: PrivateRoute,
    roles: ['ADMIN'],
    icon: 'plus-square',
    children: [
        {
            path: '/apps/doctor/list',
            name: 'Doctors',
            component: Doctors,
            route: PrivateRoute,
        },
        {
            path: '/apps/doctor/details',
            name: 'DoctorDetail',
            component: DoctorDetail,
            route: PrivateRoute,
        },
        {
            path: '/apps/doctor/create',
            name: 'Doctor Create',
            component: DoctorCreate,
            route: PrivateRoute,
        },
        {
            path: '/apps/doctor/edit',
            name: 'Doctor Edit',
            component: DoctorEdit,
            route: PrivateRoute,
        },
        {
            path: '/apps/doctor/search',
            name: 'Search Doctor',
            component: SearchDoctor,
            route: PrivateRoute,
        },
        {
            path: '/apps/doctor/deparment/list',
            name: 'Deparments',
            component: Departments,
            route: PrivateRoute,
        },
        {
            path: '/apps/doctor/deparment/create',
            name: 'Deparments',
            component: CreateDepartment,
            route: PrivateRoute,
        },
        {
            path: '/apps/doctor/deparment/edit',
            name: 'Deparments',
            component: DepartmentEdit,
            route: PrivateRoute,
        },
        {
            path: '/apps/doctor/qualification/list',
            name: 'Qualification',
            component: Qualification,
            route: PrivateRoute,
        },
        {
            path: '/apps/doctor/qualification/create',
            name: 'Qualification',
            component: QualificationCreate,
            route: PrivateRoute,
        },
        {
            path: '/apps/doctor/qualification/edit',
            name: 'Qualification',
            component: QualificationEdit,
            route: PrivateRoute,
        },
        {
            path: '/apps/doctor/specialization/list',
            name: 'Specialization',
            component: Specialization,
            route: PrivateRoute,
        },
        {
            path: '/apps/doctor/specialization/create',
            name: 'Specialization',
            component: SpecializationCreate,
            route: PrivateRoute,
        },
        {
            path: '/apps/doctor/specialization/edit',
            name: 'Specialization',
            component: specializationEdit,
            route: PrivateRoute,
        }
    ],
};

const procedureAppRoutes = {
    path: '/apps/procedure',
    name: 'Procedure',
    route: PrivateRoute,
    roles: ['ADMIN'],
    icon: 'activity',
    children: [
        {
            path: '/apps/procedure/list',
            name: 'Procedure',
            component: Procedure,
            route: PrivateRoute,
        },
        {
            path: '/apps/procedure/create',
            name: 'Procedure',
            component: ProcedureEdit,
            route: PrivateRoute,
        },
    ]
};

const facilitiesAppRoutes = {
    path: '/apps/facility/',
    name: 'Facilities',
    route: PrivateRoute,
    roles: ['ADMIN'],
    icon: 'activity',
    children: [
        {
            path: '/apps/facility/list',
            name: 'Procedure',
            component: Facilities,
            route: PrivateRoute,
        },
        {
            path: '/apps/facility/create',
            name: 'Procedure',
            component: FacilitiesCreate,
            route: PrivateRoute,
        },
        {
            path: '/apps/facility/view',
            name: 'Procedure',
            component: FacilitiesView,
            route: PrivateRoute,
        }, {
            path: '/apps/facility/edit',
            name: 'Procedure',
            component: FacilitiesEdit,
            route: PrivateRoute,
        },
    ]
};

const servicesAppRoutes = {
    path: '/apps/service/',
    name: 'Services',
    route: PrivateRoute,
    roles: ['ADMIN'],
    icon: 'activity',
    children: [
        {
            path: '/apps/service/list',
            name: 'Services',
            component: Services,
            route: PrivateRoute,
        },
        {
            path: '/apps/service/create',
            name: 'Services',
            component: ServicesEdit,
            route: PrivateRoute,
        },
    ]
};

const bloodbankAppRoutes = {
    path: '/apps/bloodbank',
    name: 'HospyUsers',
    route: PrivateRoute,
    roles: ['ADMIN'],
    icon: 'activity',
    children: [
        {
            path: '/apps/bloodbank/list',
            name: 'Hospitals',
            component: BloodBank,
            route: PrivateRoute,
        },
        {
            path: '/apps/bloodbank/edit',
            name: 'BloodBank',
            component: BloodBankEdit,
            route: PrivateRoute,
        },
        {
            path: '/apps/bloodbank/create',
            name: 'BloodBank',
            component: BloodBankCreate,
            route: PrivateRoute,
        },
        {
            path: '/apps/bloodbank/view',
            name: 'BloodBank',
            component: BloodBankDetails,
            route: PrivateRoute,
        },
    ]
};

const ambulanceAppRoutes = {
    path: '/apps/ambulance',
    name: 'Ambulance',
    route: PrivateRoute,
    roles: ['ADMIN'],
    icon: 'activity',
    children: [
        {
            path: '/apps/ambulance/list',
            name: 'Rooms',
            component: Ambulance,
            route: PrivateRoute,
        },
        {
            path: '/apps/ambulance-provider/list',
            name: 'Rooms',
            component: AmbulanceProvider,
            route: PrivateRoute,
        },
        {
            path: '/apps/ambulance/create',
            name: 'Ambulance',
            component: AmbulanceCreate,
            route: PrivateRoute,
        },

        {
            path: '/apps/ambulance/edit',
            name: 'Ambulance',
            component: AmbulanceEdit,
            route: PrivateRoute,
        },
        {
            path: '/apps/ambulance/view',
            name: 'Ambulance',
            component: AmbulanceDetails,
            route: PrivateRoute,
        },
    ]
};

const roomsAppRoutes = {
    path: '/apps/room',
    name: 'Rooms',
    route: PrivateRoute,
    roles: ['ADMIN'],
    icon: 'activity',
    children: [
        {
            path: '/apps/room/list',
            name: 'Rooms',
            component: Rooms,
            route: PrivateRoute,
        },
        {
            path: '/apps/room/create',
            name: 'Rooms',
            component: RoomCreate,
            route: PrivateRoute,
        },
        {
            path: '/apps/room/edit',
            name: 'Rooms',
            component: RoomEdit,
            route: PrivateRoute,
        },
        {
            path: '/apps/room/view',
            name: 'Rooms',
            component: RoomView,
            route: PrivateRoute,
        },
    ]
};

const hospyUsersAppRoutes = {
    path: '/apps/hospyuser',
    name: 'HospyUsers',
    route: PrivateRoute,
    roles: ['ADMIN'],
    icon: 'activity',
    children: [
        {
            path: '/apps/hospyuser/list',
            name: 'Hospitals',
            component: HospyUsers,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospyuser/edit',
            name: 'Hospitals',
            component: HospyUserEdit,
            route: PrivateRoute,
        },
        {
            path: '/apps/hospyuser/create',
            name: 'Hospitals',
            component: HospyUserCreate,
            route: PrivateRoute,
        },
    ]
};

const appRoutes = [
    hospitalAppRoutes,
    doctorAppRoutes,
    procedureAppRoutes,
    facilitiesAppRoutes,
    servicesAppRoutes,
    bloodbankAppRoutes,
    ambulanceAppRoutes,
    roomsAppRoutes,
    hospyUsersAppRoutes,
];

// pages
const extrapagesRoutes = {
    path: '/pages',
    name: 'Pages',
    icon: 'package',
    header: 'Custom',
    children: [
        {
            path: '/pages/starter',
            name: 'Starter',
            component: Starter,
            route: PrivateRoute,
        },
        {
            path: '/pages/timeline',
            name: 'Timeline',
            component: Timeline,
            route: PrivateRoute,
        },
        {
            path: '/pages/sitemap',
            name: 'Sitemap',
            component: Sitemap,
            route: PrivateRoute,
        },
        {
            path: '/pages/invoice',
            name: 'Invoice',
            component: Invoice,
            route: PrivateRoute,
        },
        {
            path: '/pages/faq',
            name: 'FAQ',
            component: FAQ,
            route: PrivateRoute,
        },
        {
            path: '/pages/serach-results',
            name: 'Search Results',
            component: SearchResults,
            route: PrivateRoute,
        },
        {
            path: '/pages/pricing',
            name: 'Pricing',
            component: Pricing,
            route: PrivateRoute,
        },
        {
            path: '/pages/gallery',
            name: 'Gallery',
            component: Gallery,
            route: PrivateRoute,
        },
        {
            path: '/pages/error-404-alt',
            name: 'Error - 404-alt',
            component: Error404Alt,
            route: PrivateRoute,
        },
    ],
};

// ui
const uiRoutes = {
    path: '/ui',
    name: 'Components',
    icon: 'pocket',
    header: 'UI Elements',
    children: [
        {
            path: '/ui/base',
            name: 'Base UI',
            children: [
                {
                    path: '/ui/buttons',
                    name: 'Buttons',
                    component: Buttons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/cards',
                    name: 'Cards',
                    component: Cards,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/avatars',
                    name: 'Avatars',
                    component: Avatars,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/portlets',
                    name: 'Portlets',
                    component: Portlets,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/tabs-accordions',
                    name: 'Tabs & Accordions',
                    component: TabsAccordions,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/progress',
                    name: 'Progress',
                    component: Progress,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/modals',
                    name: 'Modals',
                    component: Modals,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/notifications',
                    name: 'Notifications',
                    component: Notifications,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/offcanvas',
                    name: 'Offcanvas',
                    component: Offcanvases,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/placeholders',
                    name: 'Placeholders',
                    component: Placeholders,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/spinners',
                    name: 'Spinners',
                    component: Spinners,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/images',
                    name: 'Images',
                    component: Images,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/carousel',
                    name: 'Carousel',
                    component: Carousels,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/listgroups',
                    name: 'List Groups',
                    component: ListGroups,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/embedvideo',
                    name: 'EmbedVideo',
                    component: EmbedVideo,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/dropdowns',
                    name: 'Dropdowns',
                    component: Dropdowns,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/ribbons',
                    name: 'Ribbons',
                    component: Ribbons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/tooltips-popovers',
                    name: 'Tooltips & Popovers',
                    component: TooltipsPopovers,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/typography',
                    name: 'Typography',
                    component: Typography,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/grid',
                    name: 'Grid',
                    component: Grid,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/general',
                    name: 'General UI',
                    component: GeneralUI,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/extended',
            name: 'Extended UI',
            children: [
                {
                    path: '/extended-ui/nestable',
                    name: 'Nestable List',
                    component: NestableList,
                    route: PrivateRoute,
                },
                {
                    path: '/extended-ui/dragdrop',
                    name: 'Drag and Drop',
                    component: DragDrop,
                    route: PrivateRoute,
                },
                {
                    path: '/extended-ui/rangesliders',
                    name: 'Range Sliders',
                    component: RangeSliders,
                    route: PrivateRoute,
                },
                {
                    path: '/extended-ui/animation',
                    name: 'Animation',
                    component: Animation,
                    route: PrivateRoute,
                },
                {
                    path: '/extended-ui/sweet-alert',
                    name: 'Sweet Alert',
                    component: SweetAlerts,
                    route: PrivateRoute,
                },
                {
                    path: '/extended-ui/tour',
                    name: 'Tour Page',
                    component: TourPage,
                    route: PrivateRoute,
                },
                {
                    path: '/extended-ui/loading-buttons',
                    name: 'Loading Buttons',
                    component: LoadingButtons,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/widgets',
            name: 'Widgets',
            component: Widgets,
            route: PrivateRoute,
        },
        {
            path: '/ui/icons',
            name: 'Icons',
            children: [
                {
                    path: '/ui/icons/two-tone',
                    name: 'Two Tone Icons',
                    component: TwoToneIcons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/feather',
                    name: 'Feather Icons',
                    component: FeatherIcons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/dripicons',
                    name: 'Dripicons',
                    component: Dripicons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/mdi',
                    name: 'Material Design',
                    component: MDIIcons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/font-awesome',
                    name: 'Font Awesome 5',
                    component: FontAwesomeIcons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/themify',
                    name: 'Themify',
                    component: ThemifyIcons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/simple-line',
                    name: 'Simple Line Icons',
                    component: SimpleLineIcons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/weather',
                    name: 'Weather Icons',
                    component: WeatherIcons,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/forms',
            name: 'Forms',
            children: [
                {
                    path: '/ui/forms/basic',
                    name: 'Basic Elements',
                    component: BasicForms,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/advanced',
                    name: 'Form Advanced',
                    component: FormAdvanced,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/validation',
                    name: 'Form Validation',
                    component: FormValidation,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/wizard',
                    name: 'Form Wizard',
                    component: FormWizard,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/upload',
                    name: 'File Upload',
                    component: FileUpload,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/editors',
                    name: 'Editors',
                    component: Editors,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/tables',
            name: 'Tables',
            children: [
                {
                    path: '/ui/tables/basic',
                    name: 'Basic',
                    component: BasicTables,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/tables/advanced',
                    name: 'Advanced',
                    component: AdvancedTables,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/maps',
            name: 'Maps',
            children: [
                {
                    path: '/ui/googlemaps',
                    name: 'Google Maps',
                    component: GoogleMaps,
                    route: PrivateRoute,
                },
            ],
        },
    ],
};

// auth
const authRoutes: RoutesProps[] = [
    {
        path: '/auth/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/auth/register',
        name: 'Register',
        component: Register,
        route: Route,
    },
    {
        path: '/auth/confirm',
        name: 'Confirm',
        component: Confirm,
        route: Route,
    },
    {
        path: '/auth/forget-password',
        name: 'Forget Password',
        component: ForgetPassword,
        route: Route,
    },
    {
        path: '/auth/signin-signup',
        name: 'SignIn-SignUp',
        component: SignInSignUp,
        route: Route,
    },
    {
        path: '/auth/lock-screen',
        name: 'Lock Screen',
        component: LockScreen,
        route: Route,
    },
    {
        path: '/auth/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
    {
        path: '/auth/login2',
        name: 'Login2',
        component: Login2,
        route: Route,
    },
    {
        path: '/auth/logout2',
        name: 'Logout2',
        component: Logout2,
        route: Route,
    },
    {
        path: '/auth/register2',
        name: 'Register2',
        component: Register2,
        route: Route,
    },
    {
        path: '/auth/confirm2',
        name: 'Confirm2',
        component: Confirm2,
        route: Route,
    },
    {
        path: '/auth/forget-password2',
        name: 'Forget Password2',
        component: ForgetPassword2,
        route: Route,
    },
    {
        path: '/auth/signin-signup2',
        name: 'SignIn-SignUp2',
        component: SignInSignUp2,
        route: Route,
    },
    {
        path: '/auth/lock-screen2',
        name: 'Lock Screen2',
        component: LockScreen2,
        route: Route,
    },
];

// public routes
const otherPublicRoutes = [
    {
        path: '/landing',
        name: 'landing',
        component: Landing,
        route: Route,
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        route: Route,
    },
    {
        path: '/error-404',
        name: 'Error - 404',
        component: Error404,
        route: Route,
    },
    {
        path: '/error-404-two',
        name: 'Error - 404 Two',
        component: Error404Two,
        route: Route,
    },
    {
        path: '/error-500',
        name: 'Error - 500',
        component: Error500,
        route: Route,
    },
    {
        path: '/error-500-two',
        name: 'Error - 500 Two',
        component: Error500Two,
        route: Route,
    },
    {
        path: '/upcoming',
        name: 'Coming Soon',
        component: Upcoming,
        route: Route,
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const authProtectedRoutes = [rootRoute, dashboardRoutes, ...appRoutes, extrapagesRoutes, uiRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };