import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from "./index.module.css";
import { ReactComponent as SuccessIcon } from "assets/images/icons/Icon-Success.svg";
import { ReactComponent as FailureIcon } from "assets/images/icons/Icon-Failure.svg";

import clsx from 'clsx';

export const setToast = (
    label,
    type="success"
) => {

    const getPrefixIcon = () => {
        if(type === "success") {
            return <SuccessIcon />
        } else if(type === "error") {
            return <FailureIcon />
        }
    }

    const displayContent = () => {
        return (
            <div className={styles.displayContent}>
                {getPrefixIcon()}
                <span className={styles.label}>{label}</span>
            </div>
        )
    }

    const notify = () => toast(
        displayContent, {
        className: clsx(styles.toastBody, styles[type]),
        bodyClassName: styles.toastText
    });
    
    notify();
}

const CustomToastContainer = ({
    position="bottom-left",
    hideProgressBar = true,
}) => {
    return (
        <ToastContainer
            className={styles.toastContainer}
            position={position}
            hideProgressBar={hideProgressBar}
            draggable={false}
        />
    )
}


export default CustomToastContainer;