import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { api } from "helpers/api/apiCore";
import qs from "qs";
import { queryClient } from "helpers/api/query";
import { setTokens } from "helpers/api/auth";
import { clearStorage, writeToStorage } from "helpers/storage";
import { APICore, setAuthorization } from "helpers/api/apiCore";
import { Endpoints, QueryKeys, unknown_Error } from "helpers/api/constants";
import { setToast } from './../components/Toast/index';
import { reportError } from "crashlytics";

interface LoginData {
  username: string;
  password: string;
}

interface TokenData {
  token: string;
  refreshToken: string;
}

const apiCore = new APICore();

export function useLogin() {
  return useMutation<unknown, unknown, LoginData>(
    ({ username, password }) =>
      api
        .post(
          Endpoints.Account.Login,
          qs.stringify({
            grant_type: "password",
            username: username,
            password: password,
          }), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }).then((response) => response?.data),
    {
      onSuccess: (data: any) => {
        // queryClient.setQueryData(QueryKeys.tokens, data);
        // writeToStorage(QueryKeys.tokens, data);
        apiCore.setLoggedInUser(data);
        setAuthorization(data["access_token"]);
      },
      onError: (error: any) => {
        setToast(error || unknown_Error, "error");
        reportError("useLogin: useMutation: onError: error", error);
      },
    }
  );
}

export function useRefreshToken() {
  return useMutation<unknown, unknown, TokenData>(
    ({ token, refreshToken }) =>
      api
        .post<TokenData>(Endpoints.Account.RefreshToken, {
          token: token,
          refreshToken: refreshToken,
        })
        .then((response) => response?.data),
    {
      onSuccess: (data: any) => {
        queryClient.setQueryData(QueryKeys.tokens, data?.result);
        writeToStorage(QueryKeys.tokens, data?.result);
        setTokens(data?.result);
      },
      onError: (error: any) => {
        //setToast(error?.response?.data?.message || "unknown error", "error");
        reportError(
          "useRefreshToken: useMutation: onError: error",
          error as Error
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(undefined, {
          refetchInactive: true,
        });
      },
    }
  );
}
