//Logger for debugging only
export const log = (title, description, color, object) => {
    if (process.env.NODE_ENV === "development") {
      console.groupCollapsed(
        `%c${title}\n%c${description}`,
        `color: ${color}; font-weight:bold;`,
        "color: grey;"
      );
      console.log(JSON.parse(JSON.stringify(object)));
      console.groupEnd();
    }
  };