export const RegexExpressions = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  username: /^(?=[a-zA-Z0-9._]{2,18}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
  name: /^[\\p{L} .'-]+$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
};

export const Endpoints = {
  Dashboard: {
    GetDashboard: "/dashboard/getDashboard",
  },
  Common: {
    GetCity: "/city/getCity",
    GetState: "/state/getState",
    GetDistrict: "/district/getDistrict",
  },
  Account: {
    Login: "/oauth/token",
    RefreshToken: "/oauth/refreshToken"
  },
  Hospital: {
    GetHospitals: "/hospital/getHospitals",
    GetHospitalDoctors: "/doctor/getDoctorMedicalService",
    CreateHospital: "/hospital/createHospitals",
    GetAssociatedDoctors: "/hospital/getHospitalAssociatedDoctors",
    AddDoctor: "/hospitalMedicalDepartment/updateHospitalMedicalDepartment",
    AddService: "/hospitalService/createHospitalAssociatedServiceAssistance",
    AddProcedure: "/hospitalSurgicalProcedurePlan/createHospitalSurgicalProcedure",
    CreateUser: "/userManagement/createHospitalUser",
    UpdateHospitalUsers: "/userManagement/updateHospitalUser",
    UpdateHospyUsers: "/userManagement/updateUser",
    GetHopspitalType: "/hospitalType/getHospitalType",
    CreateHosptalType: "/hospitalType/createHospitalType",
    UpdateHosptalType: "/hospitalType/updateHospitalType",
    GetPackages: "/hospitalHealthCheckupPackage/getHospitalAssociatedHealthCheckupPackage",
    GetProcedure: "/hospitalSurgicalProcedurePlan/getHospitalSurgicalProcedure",
    CreateProcedure: "/hospitalSurgicalProcedurePlan/createHospitalSurgicalProcedure",
    GetFacilities: "/hospitalFacility/getHospitalFacility",
    GetAssociatedPackages: "/hospitalHealthCheckupPackage/getHospitalAssociatedHealthCheckupPackage",
    CreateHospitalAssociatedPackage: "/hospitalHealthCheckupPackage/createHospitalAssociatedHealthCheckupPackage",
    UpdateHopsitalTypeLockStatus: "/hospitalType/updateLockStatus",
    DeleteHospitalType: "/hospitalType/deleteHospitalType",
    updateHospitalStatus: "/hospital/updateLockStatus",
    DeleteHospital: "/hospital/deleteHospital",
    UpdateHospital: "/hospital/updateHospital",
    DeleteHospitalDoctor: "/doctor/deleteDoctorMedicalService",
    DeleteHospitalPacakges: "/hospitalHealthCheckupPackage/deleteHospitalAssociatedHealthCheckupPackage",
    updateHospitalAssociatedPackage: "/hospitalHealthCheckupPackage/updateHospitalAssociatedHealthCheckupPackage",
    getHospitalDepartment: "/hospitalMedicalDepartment/getHospitalAssociatedMedicalDepartment",
    updateHospitalDepartment: "/hospitalMedicalDepartment/updateHospitalAssociatedMedicalDepartment"
  },
  Doctor: {
    GetDoctors: "/doctor/getDoctors",
    CreateDoctor: "/doctor/createDoctors",
    UpdateDoctor: "/doctor/updateDoctor",
    UpdateHospitalDoctor: "/doctor/updateDoctorMedicalService",
    UpdateLockStatus: "/doctor/updateLockStatus",
    DeleteDoctor: "/doctor/deleteDoctor",
    GetDepartment: "/department/getDepartment",
    GetDoctorQualification: "/doctorQualification/getdoctorQualification",
    CreateQualification: "/doctorQualification/createDoctorQualification",
    DeleteQualification: "/doctorQualification/deleteDoctorQualification",
    UpdateQualification: "/doctorQualification/updateDoctorQualification",
    GetDoctorSpecialization: "/doctorSpecialization/getDoctorSpecialization",
    CreateDepartment: "/department/createDepartment",
    UpdateDepartment: "/department/updateDepartment",
    DeleteDepartment: "/department/deleteDepartment",
    UpdateDepartmentLockStatus: "/department/updateLockStatus",
    UpdateQualificationLockStatus: "/doctorQualification/updateLockStatus",
    UpdateSpecificationLockStatus: "/doctorSpecialization/updateLockStatus",
    CreateSpecialization: "/doctorSpecialization/createDoctorSpecialization",
    UpdateSpecialization: "/doctorSpecialization/updateDoctorSpecialization",
    DeleteSpecialization: "/doctorSpecialization/deleteDoctorSpecialization",
  },
  Users: {
    GetUsers: "/userManagement/getUsers",
    CreateUser: "/userManagement/createUser",
    DeleteUsers: "/userManagement/deleteUser",
    updateHospitalUserLockStatus: "/userManagement/updateLockStatus"
  },
  BloodBank: {
    GetBloodBank: "/bloodBank/getBloodBank",
    CreateBloodBank: "/bloodBank/createBloodBank",
    DeleteBloodBank: "/bloodBank/deleteBloodBank",
    UpdateBloodBank: "/bloodBank/updateBloodBank",
    UpdateBloodBankLockStatus: "/bloodBank/updateLockStatus",
  },
  Amulance: {
    GetAmbulanceServiceProvider: "/ambulance/getAmbulanceServiceProvider",
    CreateAmbulanceServiceProvider: "/ambulance/createAmbulanceServiceProvider",
    UpdateAmbulanceServiceProvider: "/ambulance/updateAmbulanceServiceProvider",
    DeleteAmbulanceServiceProvider: "/ambulance/deleteAmbulanceServiceProvider",
    UpdateAmbulanceLockStatus: "/ambulance/updateLockStatus",
  },
  Room: {
    GetRooms: "/medicalRoom/getMedicalRoom",
    CreateRoom: "/medicalRoom/createMedicalRoom",
    DeleteRoom: "/medicalRoom/deleteMedicalRoom",
    UpdateRoom: "/medicalRoom/updateMedicalRoom",
    UpdateRoomLockStatus: "/medicalRoom/updateLockStatus",
  },
  Service: {
    GetServicesLevel1: "/medicalService/getMedicalServiceLevelOne",
    CreateServiceLevel1: "/medicalService/createMedicalServiceLevelOne",
    GetAssociatedServices: "/hospitalService/getHospitalAssociatedServiceAssistance",
    updateAssociateServices: "/hospitalService/updateHospitalAssociatedServiceAssistance",
    CreateServiceLevel2: "/medicalService/createMedicalServiceLevelTwo",
    GetServicesLevel2: "/medicalService/getMedicalServiceLevelTwo",
    CreateServiceLevel3: "/medicalService/createMedicalServiceLevelThree",
    GetServicesLevel3: "/medicalService/getMedicalServiceLevelThree",
    CreateServiceLevel4: "/medicalService/createMedicalServiceLevelFour",
    GetServicesLevel4: "/medicalService/getMedicalServiceLevelFour",
    UpdateServiceLevel1: "/medicalService/updateMedicalServiceLevelOne",
    UpdateServiceLevel2: "/medicalService/updateMedicalServiceLevelTwo",
    UpdateServiceLevel3: "/medicalService/updateMedicalServiceLevelThree",
    UpdateServiceLevel4: "/medicalService/updateMedicalServiceLevelFour",
    DeleteServiceLevel1: "/medicalService/deleteMedicalServiceLevelOne",
    DeleteServiceLevel2: "/medicalService/deleteMedicalServiceLevelTwo",
    DeleteServiceLevel3: "/medicalService/deleteMedicalServiceLevelThree",
    DeleteServiceLevel4: "/medicalService/deleteMedicalServiceLevelFour",
    DeleteHospitalService: "/hospitalService/deleteHospitalAssociatedServiceAssistance"
  },
  Facility: {
    GetFacilities: "/hospitalFacility/getHospitalFacility",
    CreateFacility: "/hospitalFacility/createHospitalFacility",
    DeleteFacility: "/hospitalFacility/deleteHospitalFacility",
    UpdateFacility: "/hospitalFacility/updateHospitalFacility",
    UpdateFacilityLockStatus: "/hospitalFacility/updateLockStatus",
    GetHospitalFacilities: "/hospitalAssociatedFacilityService/getHospitalAssociatedFacility",
    UpdateHospitalFacility: "/hospitalAssociatedFacilityService/updateHospitalAssociatedFacility"
  },
  Procedure: {
    GetProcedureLevel1: "/medicalSurgeryProcedure/getMedicalProcedureLevelOne",
    CreateProcedureLevel1: "/medicalSurgeryProcedure/createMedicalSurgeryProcedureLevelOne",
    UpdateProcedureLevel1: "/medicalSurgeryProcedure/updateMedicalSurgeryProcedureLevelOne",
    DeleteProcedureLevel1: "/medicalSurgeryProcedure/deleteMedicalProcedureLevelOne",
    CreateProcedureLevel2: "/medicalSurgeryProcedure/createMedicalSurgeryProcedureLevelTwo",
    GetProcedureLevel2: "/medicalSurgeryProcedure/getMedicalProcedureLevelTwo",
    UpdateProcedureLevel2: "/medicalSurgeryProcedure/updateMedicalSurgeryProcedureLevelTwo",
    DeleteProcedureLevel2: "/medicalSurgeryProcedure/deleteMedicalProcedureLevelTwo",
    CreateProcedureLevel3: "/medicalSurgeryProcedure/createMedicalSurgeryProcedureLevelThree",
    GetProcedureLevel3: "/medicalSurgeryProcedure/getMedicalProcedureLevelThree",
    UpdateProcedureLevel3: "/medicalSurgeryProcedure/updateMedicalSurgeryProcedureLevelThree",
    DeleteProcedureLevel3: "/medicalSurgeryProcedure/deleteMedicalProcedureLevelThree",
    GetProcedureLevel4: "/medicalSurgeryProcedure/getMedicalProcedureLevelFour",
    UpdateProcedureLevel4: "/medicalSurgeryProcedure/updateMedicalSurgeryProcedureLevelFour",
    DeleteProcedureLevel4: "/medicalSurgeryProcedure/deleteMedicalProcedureLevelFour",
    CreateProcedureLevel4: "/medicalSurgeryProcedure/createMedicalSurgeryProcedureLevelFour",
    GetAssociatedProcedure: "/hospitalSurgicalProcedurePlan/getHospitalSurgicalProcedure",
    updateAssociateProcedure: "/hospitalSurgicalProcedurePlan/updateHospitalSurgicalProcedurePlan",
    DeleteHospitalProcedure: "/hospitalSurgicalProcedurePlan/deleteHospitalSurgicalProcedurePlan"
  },
  Image: {
    uploadImage: "/medicalRoom/uploadImageMedicalRoom",
  },
  Shared: {
    sharedImage: "/sharedApi/uploadImage"
  }
};

export const QueryKeys = {
  user: "user",
  tokens: "tokens",
  tempTokens: "tempTokens",
};

export const StorageKey = "@HOSPY_QUERY_CACHE";
export const unknown_Error = 'Something went wrong';
