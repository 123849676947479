import { useEffect, useState } from "react";
import { useRefreshToken } from "queries/account";
import { hasTokenExpired } from "helpers/api/auth";
import { readFromStorage } from "helpers/storage";
import { queryClient } from "helpers/api/query";
import { QueryKeys } from "helpers/api/constants";

export default function RefreshTokenHelper() {
  const {
    mutateAsync: refreshToken,
    status: refreshTokenStatus,
  } = useRefreshToken();
  const [queryClientHydrated, setQueryClientHydrated] = useState(false);
  const [isTokenValidated, setIsTokenValidated] = useState(false);

  //Rehydrate the query client
  useEffect(() => {
    readFromStorage().then(() => {
      setQueryClientHydrated(true);
    });
  }, []);

  //Refresh token if it already expired
  useEffect(() => {
    if (queryClientHydrated) {
      const tokens = queryClient.getQueryData(QueryKeys.tokens);
      const isTokenExpired = hasTokenExpired(tokens?.token);
      if (tokens?.token && isTokenExpired) {
        refreshToken(tokens);
      } else {
        setIsTokenValidated(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClientHydrated]);

  //Check if token refresh is done
  useEffect(() => {
    if (refreshTokenStatus === "success" || refreshTokenStatus === "error") {
      setIsTokenValidated(true);
    }
  }, [refreshTokenStatus]);

  return null;
}
