
import { APICore } from './apiCore';
import jwt_decode from 'jwt-decode';

const api = new APICore();
// account

export const hasTokenExpired = (token?: string): boolean => {
    if (token) {
      const tokenData: {exp: number} = jwt_decode(token);
      const current_time = new Date().getTime() / 1000;
      return current_time > tokenData.exp;
    } else {
      return false;
    }
  };
  
  export function getAccessToken(): string | null {
    const token = localStorage.getItem("token");
    return token;
  }
  
  export function getRefreshToken(): string | null {
    const refreshToken = localStorage.getItem("refreshToken");
    return refreshToken;
  }
  
  export function setTokens({ token, refreshToken}: { token: string, refreshToken: string }): void {
    localStorage.setItem('token', token)
    localStorage.setItem('refreshToken', refreshToken)
  }

function login(params: { username: string; password: string }) {
    const baseUrl = '/login/';
    return api.create(`${baseUrl}`, params);
}

function logout() {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
    const baseUrl = '/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword };